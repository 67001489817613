import {
    queryCouponList
} from '@/utils/coupon.js';
export default {
    name: "CouponHistory",
    data() {
        return {
            active: 0,
            list: [],
            isFinish: false
        }
    },
    created() {
        this.getCoupon(1, 1)
    },
    methods: {
        tabChange() {
            if (this.active == 1) {
                this.getCoupon(1, 5)
            } else {
                this.getCoupon(1, 1)
            }
        },
        getCoupon(sort, status) {
            this.isFinish = false
            this.list = []
            let data = {
                memberId: this.$cookies.get('memberId'),
                sortMethod: sort,
                status: status
            };
            queryCouponList(data).then(res => {
                if (res && res.code == "0") {
                    this.list = res.data.couponUser_list || []
                }
                this.isFinish = true
            })
        },
    }
}