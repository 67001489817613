<template>
  <div class="coupon-history">
    <div class="m-header">
      <div class="tab">
        <van-tabs v-model:active="active"  @change="tabChange">
          <van-tab title="已使用"></van-tab>
          <van-tab title="已过期"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div v-if="isFinish" class="main">
      <template v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index" class="m-li">
          <div class="t-m flex">
            <img src="@/assets/img/user/coupon/tou.png" alt="" />
            <div class="center">
              <p>{{ item.coupon_name || '' }}</p>
              <p>{{ item.goodName || '' }}</p>
            </div>
            <div class="right">
              <div class="right-top flex align-end flex-center">
                <p class="unit">￥</p>
                <p class="r-i">{{ item.face_value }}</p>
              </div>
              <p class="r-b">{{ item.coupon_qualification === 0 ? '无门槛' : (Number(item.coupon_qualification || 0) > 0 ? '满' + item.coupon_qualification + '可用' : '') }}</p>
            </div>
          </div>
          <div class="c-m flex">
            <div class="c-l">
              <p class="str">{{ item.coupon_type }}</p>
              <div class="c-b flex">
                <p>有效期至{{ item.valid_end_date }}</p>
              </div>
            </div>
            <img v-if="active == 1" src="@/assets/img/user/coupon/date.png" class="date" />
            <img v-else src="@/assets/img/user/coupon/used.png" class="date" />
          </div>
        </div>
        <p class="bottom">——  已经到底啦  ——</p>
      </template>
      <template v-else>
        <div class="list-no">
          <img src="@/assets/img/user/coupon/no-data.png" alt="" />
          <p>暂无过期优惠券</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.coupon-history {
  .m-header {
    height: 52px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    z-index: 10;
    .tab {
      .van-tabs--line {
        width: 224px;
        margin: 0 auto;
      }
      .van-tabs__wrap {
        height: auto !important;
      }
      .van-tabs__nav {
        padding-top: 16px !important;
        padding-bottom: 15px !important;
        justify-content: space-between;
      }
      .van-tabs__line {
        width: 28px !important;
        height: 2px !important;
        background: #e62129 !important;
        bottom: 0 !important;
      }
      .van-tab {
        height: 21px !important;
        font-size: 15px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #999999 !important;
        line-height: 21px !important;
        padding: 0 !important;
        flex: unset !important;
        width: auto !important;
      }
      .van-tab--active {
        color: #e62129 !important;
      }
    }
  }
}
</style>