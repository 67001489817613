import axios from "./request"

// 注册
export const queryCouponList = (data) => {
    return axios({
        url: "/api/member/couponhavegottenlist",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
